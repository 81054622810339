import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { axiosApiInstance } from "app/Api/axios";
import { LOGIN_URL } from "app/Api/ApiEndPoint";
import { ADMIN_LOGIN_URL } from "app/Api/ApiEndPoint";
import { clearLoggedInUserInvalidCredential } from "app/utils/utils";
import { Token } from "@mui/icons-material";
let checkIn = localStorage.getItem("checkIn");
let checkOut = localStorage.getItem("checkOut");
const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  role: null,
  checkIn: checkIn === null || undefined ? "false" : checkIn,
  checkOut: checkOut === null || undefined ? "true" : checkOut,
  userName: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      const { user, role } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
        role,
        userName: user?.employeeData?.firstName,
      };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null, userName: "" };
    }
    case "UPDATE_USER_NAME": {
      const { userName } = action.payload;
      return { ...state, isAuthenticated: true, userName: userName };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }
    case "CHECK_IN": {
      const { checkIn } = action.payload;

      return { ...state, isAuthenticated: true, checkIn: checkIn };
    }
    case "CHECK_OUT": {
      const { checkOut } = action.payload;

      return { ...state, isAuthenticated: true, checkOut: checkOut };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
  checkIn: () => {},
  checkOut: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  
  const login = async (userId, userPassword) => {
    const response = await axiosApiInstance.post(LOGIN_URL, {
      userId: userId,
      userPassword: userPassword,
    });
    if (response?.data) {
      const { data: user, roleData: role } = response?.data || {};
      dispatch({ type: "LOGIN", payload: { user, role } });
    }
    return response;
  };

  const adminLogin = async (adminId, adminPassword) => {
    const response = await axiosApiInstance.post(ADMIN_LOGIN_URL, {
      adminId: adminId,
      adminPassword: adminPassword,
    });
    if (response?.data) {
      const { data: user } = response?.data || {};
      dispatch({ type: "ADMINLOGIN", payload: { user } });
    }

    return response;
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });
    const { user } = response.data;

    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    clearLoggedInUserInvalidCredential();
   
    navigate("/login");
  };
  const CheckIn = (checkIn) => {
    dispatch({ type: "CHECK_IN", payload: { checkIn } });
  };
  const CheckOut = (checkOut) => {
    dispatch({ type: "CHECK_OUT", payload: { checkOut } });
  };
  const UserName = (userName) => {
    dispatch({ type: "UPDATE_USER_NAME", payload: { userName } });
  };
  useEffect(() => {
    // const isLoggedIn = state.isAuthenticated;
    const tokan = localStorage.getItem("tokan");
    const tokenExpirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
    try {
      if (tokan) {
       setInterval(() => {
          const now = new Date().getTime();

          if (now > tokenExpirationTime) {
            clearInterval(tokenExpirationTime);
            localStorage.removeItem("tokan");
            localStorage.removeItem("role");
            localStorage.removeItem("empData");
            localStorage.removeItem("admin");
            clearLoggedInUserInvalidCredential();
          }
        }, 6000);
      }
    } catch (error) {}

    return () => {
      clearInterval(tokenExpirationTime);
    };
  }, [state.isAuthenticated]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data } = await axios.get("/api/auth/profile");
  //       dispatch({
  //         type: "INIT",
  //         payload: { isAuthenticated: false, user: data.user },
  //       });
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: "INIT",
  //         payload: { isAuthenticated: false, user: null },
  //       });
  //     }
  //   })();
  // }, []);

  // SHOW LOADER
  // if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        adminLogin,
        CheckIn,
        CheckOut,
        UserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
