import "./blog.css";
import React from "react";
import CallToActionSection from "../commonComponent/CallToActionSection";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
const AttandanceManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isactive, setisActive] = useState(null);

  const sectionRefs = {
    section0: useRef(null),
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
  };
  const scrollToSection = (section) => {
    const element = sectionRefs[section].current;
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };

  // Scroll event handler to detect which section is in view
  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.keys(sectionRefs); // Get keys (section names)
      let foundActiveSection = "";

      // Check each section to see if it's in the viewport
      sections.forEach((section) => {
        const element = sectionRefs[section].current;
        const rect = element.getBoundingClientRect();

        // If the section is in the viewport (based on top and bottom visibility)
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          foundActiveSection = section; // Update active section
        }
      });

      if (foundActiveSection !== "") {
        setisActive(foundActiveSection); // Set active section
      }
    };

    // Listen to scroll events
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const blogTitles = [
    {
      title:
        "Top Benefits of Implementing Attendance Management Software in Your Business",
      img: "assets/img/blog/Top_Benefits_of_Implementing.jpg",
      alt: "Benefits of Implementing Attendance Management image",
      link: "/blog-top-benefits-of-implementing-attendance-management-software-in-your-business",
    },
    {
      title:
        "The Future of Employee Time and Attendance: Trends in Attendance Management Software",
      img: "assets/img/blog/The_Future_of_Employee_Time.jpg",
      alt: "The Future of Employee Time and Attendance image",
      link: "/blog-future-of-employee-time-and-attendance-trends-in-management-software",
    },
    {
      title:
        "How Efficient Attendance Management Boosts Productivity and Reduces Costs",
      img: "assets/img/blog/How_Efficient_Attendance_Management.jpg",
      alt: "How Efficient Attendance Management Boosts image",
      link: "/blog-how-efficient-attendance-management-boosts-productivity-reduces-costs",
    },

    {
      title:
        "How Attendance Management Software Improves Accuracy and Saves Time",
      img: "assets/img/blog/How_Attendance_Management.jpg",
      alt: "How Attendance Management Software Improves Accuracy image",
      link: "/blog-how-attendance-management-software-improves-accuracy-and-saves-time",
    },
    {
      title: "Why Your Company Needs a Modern Attendance Management System",
      img: "assets/img/blog/why-your-company-needs-a-modern-attendance-management-system.jpg",
      alt: "why-your-company-needs-a-modern-attendance-management-system",
      link: "/blog-why-your-company-needs-a-modern-attendance-management-system",
    },
    {
      title: "Advanced Attendance Tracking Solutions Help Reduce Time Theft",
      img: "assets/img/blog/how-attendance-management-tracking-reduces-time-theft.jpg",
      alt: "how-attendance-management-tracking-reduces-time-theft",
      link: "/blog-advanced-attendance-tracking-solutions-help-reduce-time-theft",
    },
    {
      title:
        "Streamlining HR Operations: The Role of Attendance Management Software",
      img: "assets/img/blog/the-role-of-attendace-management-software-in-hr-operation.jpg",
      alt: "the-role-of-attendace-management-software-in-hr-operation",
      link: "/blog-streamlining-hr-operations-the-role-of-attendance-management-software",
    },
    {
      title: "Key Features to Look for in an Attendance Management System",
      img: "assets/img/blog/key-features-attendance-management-system.jpg",
      alt: "key-features-attendance-management-system",
      link: "/blog-key-features-to-Look-for-in-an-attendance-management-system",
    },
    {
      title: "5 Common Attendance Management Challenges and How to Solve Them",
      img: "assets/img/blog/5-common-attendance-management-challenges-and-solutions.jpg",
      alt: "5-common-attendance-management-challenges-and-solutions",
      link: "/blog-5-common-attendance-management-challenges-and-how-to-solve-them",
    },
    {
      title:
        "Boosting Employee Accountability with Smart Attendance Management Solutions.",
      img: "assets/img/blog/Boosting Employee Accountability with Smart Attendance.jpg",
      alt: "Boosting Employee Accountability with Smart Attendance Management Solutions",
      link: "/blog-boosting-employee-accountability-with-smart-attendance-management-solutions",
    },
    {
      title: "Enhancing Remote Work Management with Attendance Management App",
      img: "assets/img/blog/Enhancing Remote Work Management with Attendance.jpg",
      alt: "Enhancing Remote Work Management with Attendance Management App",
      link: "/blog-enhancing-remote-work-management-with-attendance-management-app",
    },
    {
      title:
        "The Importance of Attendance Management for Compliance and Audits",
      img: "assets/img/blog/The Importance of Attendance Management for.jpg",
      alt: "The Importance of Attendance Management for Compliance and Audits",
      link: "/blog-the-importance-of-attendance-management-for-Compliance-and-audits",
    },
    {
      title:
        "How to Choose the Right Attendance Management Software for Your Business",
      img: "assets/img/blog/How to Choose the Right Attendance Management Software for.jpg",
      alt: "How to Choose the Right Attendance Management Software for Your Business",
      link: "/blog-how-to-choose-the-right-attendance-management-software-for-your-business",
    },
    {
      title:
        "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      img: "assets/img/blog/Real-Time Attendance Tracking Benefits for Large and.jpg",
      alt: "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      link: "/blog-real-time-attendance-tracking-benefits-for-large-and-small-businesses",
    },
  ];

  const listItems = [
    {
      id: "section1",
      label: "Understanding Manual Attendance Management",
    },
    {
      id: "section2",
      label: "What is Automated Attendance Management?",
    },

    {
      id: "section3",
      label: "Manual vs. Automated Attendance Management: “Comparison”",
    },
    {
      id: "section4",
      label: "Key Features of the TimeTango Attendance Management App",
    },
    {
      id: "section5",
      label: "Why Businesses Should Adopt Automated Attendance Management",
    },
  ];
  return (
    <>
      {/* // components 1 */}
      <section className="w-100 bccolor heights">
        <div className="text-center fs-18 mb-3">
          <Link to={"/blogs"}>Blog</Link>/ Manual vs. Automated Attendance
          Management: Why Technology Wins
        </div>
        <h1 className="text-center mb-5 ">
          Manual vs. Automated Attendance Management: Why Technology Wins
        </h1>
      </section>
      {/* component 2 */}
      <section
        className="p-20 "
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="row w-100 justify-content-center gap-2">
          <div className="col-3 card-blog hidden" style={{ height: "400px" }}>
            <ul className="p-2 mt-3 mb-3 ulist" style={{ cursor: "pointer" }}>
              {listItems.map((item, index) => (
                <li
                  key={index}
                  className={`p-1 ${isactive === item.id ? "active" : null}`}
                  onClick={() => {
                    scrollToSection(item.id);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-md-12 col-lg-7">
            <div ref={sectionRefs.section0}>
              <p className="fs-16-js">
                In today’s fast-paced world, businesses are shifting towards
                automated solutions to streamline operations, improve
                efficiency, and reduce errors. A field that has made progress is
                the management of employee attendance.
              </p>
              <p className="fs-16-js">
                In this blog, we will compare manual attendance management with
                automated solutions. exploring why technology, tools like the
                TimeTango Attendance Management App, is the way forward for
                businesses.
              </p>
            </div>
            <div ref={sectionRefs.section1}>
              <h2 className="fs-3  fw-bold">
                Understanding Manual Attendance Management
              </h2>
              <p className="fs-16-js mb-4">
                Employees are expected to record their arrival and departure
                times or have a supervisor monitor their attendance, for them
                using this system that has been in use, for years. Has its
                drawbacks.
              </p>
              <ul className="fs-16-js">
                <li>
                  <strong> Errors and Inaccuracies:</strong>
                  Mistakes are bound to happen when tracking attendance manually
                  as a misreading of information or a small error could result
                  in differences, in employees recorded work hours.
                </li>
                <li>
                  <strong>Time-Consuming: </strong> TManual processes are often
                  slow and require administrative effort. Each employee&#39;s
                  attendance data must be individually recorded and verified,
                  taking up valuable time.
                </li>
                <li>
                  <strong>Lack of Real-Time Insights: </strong> With manual
                  tracking, it can be challenging to get immediate insights into
                  attendance patterns or any discrepancies in the system, making
                  it difficult for HR teams to act promptly.
                </li>
              </ul>
            </div>
            <div ref={sectionRefs.section2}>
              <h2 className="fs-3  fw-bold">
                What is Automated Attendance Management?
              </h2>
              <p className="fs-16-js">
                Managing attendance, through automation entails the utilization
                of technology and software to monitor and document employee
                presence at work. In comparison, to procedures automated systems
                have the capability to gather data immediately offering reports
                and analytical insights.
              </p>
              <p className="fs-16-js">
                The TimeTango Attendance Management App is one such example. It
                leverages modern technology to offer businesses a reliable and
                efficient solution to manage their workforce.
              </p>
            </div>
            <div ref={sectionRefs.section3}>
              <div className="text-center mt-5 mb-4">
                <h2 className="fs-3  fw-bold">
                  Manual vs. Automated Attendance Management: “Comparison”
                </h2>
              </div>
              <div className="mx-auto">
                <table className="table table-bordered table-striped table-hover fs-16-js">
                  <thead className="thead-dark ">
                    <tr>
                      <th className="fs-16-js text-center">Criteria</th>
                      <th className="fs-16-js text-center">
                        Manual Attendance
                      </th>
                      <th className="fs-16-js text-center">
                        Automated Attendance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Accuracy and Reliability</td>
                      <td>
                        Tracking attendance manually often leads to errors, such
                        as employees forgetting to sign in, or managers
                        misreading attendance sheets, which can lead to
                        inaccuracies and payroll problems.
                      </td>
                      <td>
                        Utilizing tools like TimeTango enhances accuracy.
                        Automated systems minimize errors and fraudulent
                        activities, ensuring accurate compensation for work
                        hours.
                      </td>
                    </tr>
                    <tr>
                      <td>Efficiency and Time-Saving</td>
                      <td>
                        Manual tracking consumes a lot of time and resources.
                        Employees must sign in or clock in, and managers need to
                        validate records, making the process slow and
                        monotonous.
                      </td>
                      <td>
                        Automated systems save time by allowing employees to log
                        hours through smartphones. The system saves data
                        automatically, eliminating manual verification from HR
                        or management.
                      </td>
                    </tr>
                    <tr>
                      <td>Cost-Effectiveness</td>
                      <td>
                        High labor costs are involved in manual attendance
                        tracking. HR staff is needed to maintain records,
                        resolve discrepancies, and manage payroll.
                      </td>
                      <td>
                        Automated attendance software reduces labor costs by
                        eliminating manual record-keeping and minimizing errors.
                      </td>
                    </tr>
                    <tr>
                      <td>Real-Time Monitoring and Analytics</td>
                      <td>
                        Traditional systems often lack real-time tracking,
                        delaying attendance updates and impacting
                        decision-making in workforce management.
                      </td>
                      <td>
                        Automated systems provide real-time updates, allowing
                        supervisors and HR departments to monitor trends and
                        absences, improving workforce management with in-depth
                        analytics.
                      </td>
                    </tr>
                    <tr>
                      <td>Employee Experience</td>
                      <td>
                        Manual tracking can be inconvenient for employees and
                        may cause disputes due to errors in recording attendance
                        or payroll issues.
                      </td>
                      <td>
                        Automated systems provide a seamless experience for
                        employees, allowing them to clock in and out via mobile
                        apps, making attendance tracking quick and easy.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-4 container " ref={sectionRefs.section4}>
              <div className="text-center mt-5 mb-5">
                <h2 className="fs-3 fw-bold">
                  Key Features of the TimeTango Attendance Management App
                </h2>
              </div>
              <div className="d-flex flex-column flex-md-row flex-md-wrap  align-item-center gap-4 justify-content-center">
                <div className="card-manual shadow-md p-3 text-center">
                  <h3 className="fs-18 fw-bold ">Real-Time Tracking</h3>
                  <p>
                    TimeTango employee attendance application provides
                    businesses with the ability to monitor work hours in time
                    for tracking of attendance records and ensures alignment,
                    between management and staff regarding attendance matters.
                  </p>
                </div>
                <div className="card-manual shadow-md p-3 text-center">
                  <h3 className="fs-18 fw-bold ">Mobile Integration</h3>
                  <p>
                    TimeTango provides a mobile app, enabling employees to clock
                    in and out from anywhere. This is particularly useful for
                    businesses with remote or field-based employees. The app
                    allows employees to mark attendance without the need for
                    physical devices.
                  </p>
                </div>
                <div className="card-manual shadow-md p-3 text-center">
                  <h3 className="fs-18 fw-bold ">Analytics and Reporting</h3>
                  <p>
                    The application provides robust reporting features enabling
                    HR departments to create reports using attendance
                    information, for tasks, like performance evaluations &amp;
                    payroll management.
                  </p>
                </div>
                <div className="card-manual shadow-md p-3 text-center">
                  <h3 className="fs-18 fw-bold ">
                    Customization and Flexibility
                  </h3>
                  <p>
                    TimeTango’s employee time tracking solution is highly
                    customizable, Businesses can customize the app to suit their
                    requirements by adjusting work schedules and policies or
                    managing shift rotations, with TimeTangos features designed
                    for various business settings.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5" ref={sectionRefs.section5}>
              <h2 className="fs-3  fw-bold">
                Why Businesses Should Adopt Automated Attendance Management
              </h2>
              <p className="fs-16">
                Adopting automated attendance management systems like TimeTango
                is a no-brainer for modern businesses.
              </p>
              <p className="text-dark fs-18 mb-2">
                <strong>Here are the key benefits:</strong>
              </p>
              <ul className="fs-16">
                <li>
                  <strong>Improved Accuracy: </strong> Automated systems
                  eliminate human errors, ensuring accurate attendance records.
                </li>
                <li>
                  <strong>Enhanced Efficiency: </strong> Time-saving features
                  like real-time tracking and mobile integration streamline the
                  process, freeing up HR teams to focus on other important
                  tasks.
                </li>
                <li>
                  <strong>Cost Savings: </strong>By reducing administrative
                  costs and improving productivity, automated attendance systems
                  lead to long-term cost savings.
                </li>
                <li>
                  <strong> Better Employee Engagement: </strong>
                  Transparency in attendance management fosters trust and
                  satisfaction among employees.
                </li>
              </ul>
              <p className="fs-16 ">
                By integrating an automated employee time management software
                into your operations, you can enhance productivity, ensure
                compliance, and provide a better experience for both employees
                and management. It’s clear that technology is the future of
                employee attendance management.
              </p>
              <p className="fs-16">
                Take the first step towards optimizing your operations- Explore
                TimeTango today.
              </p>
            </div>
          </div>
          <div
            className="col-3 hidden"
            style={{
              maxHeight: "100vw",
              overflowY: "auto",
              scrollbarWidth: "none",
              position: "sticky",
              top: 0,
            }}
          >
            <p className="text-center fs-18 fw-bold">Other Blog's</p>
            <div className="d-flex justify-content-center flex-column gap-4">
              {blogTitles.map((item, index) => (
                <div key={index} className="card-sub-blog">
                  <div>
                    <Link to={item.link}>
                      <img
                        src={item.img}
                        alt={item.alt}
                        className="w-100 h-100"
                      />
                    </Link>
                  </div>
                  <div className="px-2">
                    <Link to={item.link}>
                      <p className="text-justify fs-12 mb-0"> {item.title}</p>
                      <div className="py-2">
                        <button className="btn btn-link p-0 fs-12">
                          Continue reading <span>&rarr;</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* componnent 3 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default AttandanceManagement;

{
  /* <section className="  bg-white" style={{ paddingTop: "50px" }}>
  <div className="container">
    <div className="row d-flex align-items-center paddingx">
      <div className=" component-padding">
        <h1 className="text-center mb-5 ">
          Manual vs. Automated Attendance Management
        </h1>
      </div>
    </div>
  </div>
</section>; */
}
