import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
import { Link } from "react-router-dom";
import "./common.css";
const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Time Tango - Efficient Employee Attendance & Activity Tracking System",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "The all-in-one platform for effortless leave management, insightful activity tracking, and smooth attendance management.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  const blogTitles = [
    {
      title:
        "Top Benefits of Implementing Attendance Management Software in Your Business",
      img: "assets/img/blog/Top_Benefits_of_Implementing.jpg",
      alt: "Benefits of Implementing Attendance Management image",
      link: "/blog-top-benefits-of-implementing-attendance-management-software-in-your-business",
    },
    {
      title:
        "The Future of Employee Time and Attendance: Trends in Attendance Management Software",
      img: "assets/img/blog/The_Future_of_Employee_Time.jpg",
      alt: "The Future of Employee Time and Attendance image",
      link: "/blog-future-of-employee-time-and-attendance-trends-in-management-software",
    },
    {
      title:
        "How Efficient Attendance Management Boosts Productivity and Reduces Costs",
      img: "assets/img/blog/How_Efficient_Attendance_Management.jpg",
      alt: "How Efficient Attendance Management Boosts image",
      link: "/blog-how-efficient-attendance-management-boosts-productivity-reduces-costs",
    },
    {
      title: "Manual vs. Automated Attendance Management",
      img: "assets/img/blog/Manual_vs._Automated.jpg",
      alt: "Manual vs. Automated Attendance Management image",
      link: "/blog-manual-vs-automated-attendance-management-why-technology-wins",
    },
    {
      title:
        "How Attendance Management Software Improves Accuracy and Saves Time",
      img: "assets/img/blog/How_Attendance_Management.jpg",
      alt: "How Attendance Management Software Improves Accuracy image",
      link: "/blog-how-attendance-management-software-improves-accuracy-and-saves-time",
    },
    {
      title: "Why Your Company Needs a Modern Attendance Management System",
      img: "assets/img/blog/why-your-company-needs-a-modern-attendance-management-system.jpg",
      alt: "why-your-company-needs-a-modern-attendance-management-system",
      link: "/blog-why-your-company-needs-a-modern-attendance-management-system",
    },
    {
      title: "Advanced Attendance Tracking Solutions Help Reduce Time Theft",
      img: "assets/img/blog/how-attendance-management-tracking-reduces-time-theft.jpg",
      alt: "how-attendance-management-tracking-reduces-time-theft",
      link: "/blog-advanced-attendance-tracking-solutions-help-reduce-time-theft",
    },
    {
      title:
        "Streamlining HR Operations: The Role of Attendance Management Software",
      img: "assets/img/blog/the-role-of-attendace-management-software-in-hr-operation.jpg",
      alt: "the-role-of-attendace-management-software-in-hr-operation",
      link: "/blog-streamlining-hr-operations-the-role-of-attendance-management-software",
    },
    {
      title: "Key Features to Look for in an Attendance Management System",
      img: "assets/img/blog/key-features-attendance-management-system.jpg",
      alt: "key-features-attendance-management-system",
      link: "/blog-key-features-to-Look-for-in-an-attendance-management-system",
    },
    {
      title: "5 Common Attendance Management Challenges and How to Solve Them",
      img: "assets/img/blog/5-common-attendance-management-challenges-and-solutions.jpg",
      alt: "5-common-attendance-management-challenges-and-solutions",
      link: "/blog-5-common-attendance-management-challenges-and-how-to-solve-them",
    },
    {
      title:
        "Boosting Employee Accountability with Smart Attendance Management Solutions.",
      img: "assets/img/blog/Boosting Employee Accountability with Smart Attendance.jpg",
      alt: "Boosting Employee Accountability with Smart Attendance Management Solutions",
      link: "/blog-boosting-employee-accountability-with-smart-attendance-management-solutions",
    },
    {
      title: "Enhancing Remote Work Management with Attendance Management App",
      img: "assets/img/blog/Enhancing Remote Work Management with Attendance.jpg",
      alt: "Enhancing Remote Work Management with Attendance Management App",
      link: "/blog-enhancing-remote-work-management-with-attendance-management-app",
    },
    {
      title:
        "The Importance of Attendance Management for Compliance and Audits",
      img: "assets/img/blog/The Importance of Attendance Management for.jpg",
      alt: "The Importance of Attendance Management for Compliance and Audits",
      link: "/blog-the-importance-of-attendance-management-for-Compliance-and-audits",
    },
    {
      title:
        "How to Choose the Right Attendance Management Software for Your Business",
      img: "assets/img/blog/How to Choose the Right Attendance Management Software for.jpg",
      alt: "How to Choose the Right Attendance Management Software for Your Business",
      link: "/blog-how-to-choose-the-right-attendance-management-software-for-your-business",
    },
    {
      title:
        "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      img: "assets/img/blog/Real-Time Attendance Tracking Benefits for Large and.jpg",
      alt: "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      link: "/blog-real-time-attendance-tracking-benefits-for-large-and-small-businesses",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Time Tango - Efficient Employee Attendance & Activity Tracking System.
        </title>
        <meta
          name="description"
          content="Track employee attendance with Time Tango’s efficient and user-friendly time tracking & activity tracking software. Simplify HR management today with additional feature as Leave management system."
        />
        <meta
          property="og:title"
          content="Time Tango - Efficient Employee Attendance & Activity Tracking System"
        />
        <meta
          property="og:description"
          content="Track employee attendance with Time Tango’s efficient and user-friendly time tracking & activity tracking software. Simplify HR management today with additional feature as Leave management system."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta property="og:site_name" content="Timetango" />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <section className="section-padding bg-white mt-5 mt-lg-0">
        <div className="container">
          <div className="d-flex flex-column gap-4 align-items-center mt-4 paddingx">
            <div>
              <h1 className="text-center">Blog</h1>
            </div>
            <div
              className="d-flex gap-4 justify-content-center flex-wrap w-100 "
              style={{ rowGap: "16px" }}
            >
              {blogTitles.map((item, index) => (
                <div key={index} className="card d-flex flex-column">
                  <div>
                    <Link to={item.link}>
                      <img
                        src={item.img}
                        alt={item.alt}
                        className="w-100 h-100"
                      />
                    </Link>
                  </div>
                  <div className="px-4">
                    <Link to={item.link}>
                      <p className="fs-16">{item.title}</p>
                      <div className="py-2">
                        <button className="btn btn-link p-0">
                          Continue reading <span>&rarr;</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* componnent 2 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default Blogs;
