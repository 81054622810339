import useAuth from "app/hooks/useAuth";

import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const tokan = localStorage.getItem("tokan");

  const role = JSON.parse(localStorage.getItem("role"));
  if (isAuthenticated) {
    if (role?.roleName === "SUPER-ADMIN" || role?.roleName === "ADMIN" || role?.roleName === "HR") {
      return <>{children}</>;
    }
  }
  if (tokan) {
    if (role?.roleName === "SUPER-ADMIN" || role?.roleName === "ADMIN" || role?.roleName === "HR") {
      return <>{children}</>;
    }
  }

  return <Navigate replace to="/login" state={{ from: pathname }} />;
};

export default AuthGuard;