import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { baseURL } from "app/Api/axios";
import { differenceInSeconds } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export const formateDisplayDate = (date) => {
  if (date != undefined && date != null) {
    const dateFormate = moment(date).format("ddd-D-MMM,YYYY");
    return dateFormate;
  }
};
export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) return window.matchMedia(`(max-width: 767px)`).matches;

  return false;
}

export function isMdScreen() {
  if (window) return window.matchMedia(`(max-width: 1199px)`).matches;

  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);
  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}
// export const clearLoggedInUser = () => {
//   // sessionStorage.removeItem("tokan");
//   localStorage.removeItem("tokan");
//   // sessionStorage.removeItem("empData");
//   localStorage.removeItem("empData");
//   // sessionStorage.removeItem("role");
//   localStorage.removeItem("role");
//   localStorage.removeItem("admin");
//   localStorage.removeItem("data");
//   localStorage.removeItem("checkOut");
//   localStorage.removeItem("checkIn");
//   // window.location.replace("/login");
//   // window.location.pathname ("/login");
//   window.history.pushState('', {}, "/login");
//   const navEvent = new PopStateEvent('popstate');
//     window.dispatchEvent(navEvent);
//   // window.location="/login"
// };
export const clearLoggedInUserInvalidCredential = () => {
  // sessionStorage.removeItem("tokan");
  localStorage.removeItem("tokan");
  // sessionStorage.removeItem("empData");
  localStorage.removeItem("empData");
  localStorage.removeItem("elapsedTime");
  localStorage.removeItem("checkInTime");
  // sessionStorage.removeItem("role");
  localStorage.removeItem("role");
  localStorage.removeItem("admin");
  localStorage.removeItem("data");
  localStorage.removeItem("checkOut");
  localStorage.removeItem("checkIn");
  const navEvent = new PopStateEvent("popstate");
  window.dispatchEvent(navEvent);
};

export const regexExp = /^[6-9]\d{9}$/gi;

export const convertedDateEdit = (resDate) => {
  if (resDate) {
    const date = new Date(resDate);
    return date.toISOString().slice(0, 10);
  } else {
    return "";
  }
};

export const convertedDateForChecking = (resDate) => {
  if (resDate == null || resDate == "Invalid date") {
    return "--";
  } else {
    const date = new Date(resDate);
    return moment(date).format("yyyy-MM-DD");
  }
};

export const convertedDate = (resDate) => {
  if (resDate == null || resDate == "Invalid date") {
    return "--";
  } else {
    const date = new Date(resDate);
    return moment(date).format("DD-MM-yyyy");
  }
};

export const convertDateToTime = (resDate) => {
  if (resDate === null || resDate == "Invalid date") {
    return "--";
  } else {
    const date = new Date(resDate);
    return moment(date).format("HH:MM:SS");
  }
};

export const convertedDateToMonthyearFormatForExportFile = (resDate) => {
  if (resDate == null) {
    return "--";
  } else {
    const date = new Date(resDate);
    return moment(date).format("MM-yyyy");
  }
};
export const convertedDateToMonthyearFormat = (resDate) => {
  if (resDate == null) {
    return "--";
  } else {
    const date = new Date(resDate);
    return moment(date).format("yyyy-MM");
  }
};
export const convertedDateToMonthyearFormat2 = (resDate) => {
  let date = new Date(resDate);

  if (resDate === null) {
    return moment(currentDate).format("yyyy-MM");
  } else {
    return moment(date).format("yyyy-MM");
  }
};

export let currentDate = new Date();
const currentYr = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
export const attendanceConvertedDate = (resDate) => {
  const date = new Date(resDate);

  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  let dateString;

  // if (y < 1900 || y > currentYr || m > currentMonth) {
  //   dateString = "NaN-NaN-NaN";
  // } else {
  dateString = y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  // }
  return dateString;
};
export const attendanceConvertedMonthYear = (resDate) => {
  const date = new Date(resDate);
  const currentYr = new Date().getFullYear();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  let dateString;

  if (y < 1900 || y > currentYr || m > currentMonth) {
    dateString = "NaN-NaN-NaN";
  } else {
    dateString = y + "-" + (m <= 9 ? "0" + m : m);
  }
  return dateString;
};
export const attendanceConvertedDate2 = (resDate) => {
  const date = new Date(resDate);

  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  let dateString =
    y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);

  return dateString;
};
export const getTime = (date) => {
  const newDate = new Date(date);
  let hour = newDate.getHours();
  let minutes = newDate.getMinutes();
  let second = newDate.getSeconds();
  let newTimeString =
    (hour <= 9 ? "0" + hour : hour) +
    ":" +
    (minutes <= 9 ? "0" + minutes : minutes) +
    ":" +
    (second <= 9 ? "0" + second : second);
  return newTimeString;
};
export const useDebounce = (initialValue = "", delay) => {
  const [actualValue, setActualValue] = useState(initialValue);
  const [debounceValue, setDebounceValue] = useState(initialValue);
  useEffect(() => {
    const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
    return () => clearTimeout(debounceId);
  }, [actualValue, delay]);
  return [debounceValue, setActualValue];
};

export const errorMessage = (error) => {
  switch (error) {
    case "maxDate": {
      return "Future date is not valid!";
    }
    case "minDate": {
      return "Please select year after 1990!";
    }

    case "invalidDate": {
      return "Valid date is required!";
    }

    default: {
      return "";
    }
  }
};
export const errorMessage2 = (error) => {
  switch (error) {
    case "maxDate":
      return "Future date is not valid!";
    case "minDate": {
      return "Please select year after 1990!";
    }

    case "invalidDate": {
      return "Your date is not valid!";
    }
    case "required": {
      return "This field is required";
    }
    default: {
      return "";
    }
  }
};
export const errorMessage3 = (error, presentDate) => {
  switch (error) {
    // case "maxDate":
    //   return "Future date is not valid!";
    case "minDate": {
      return `Please select date after ${presentDate} `;
    }

    case "invalidDate": {
      return "Your date is not valid!";
    }

    default: {
      return "";
    }
  }
};

export const dateErrorMsg = (error) => {
  switch (error) {
    case "minDate": {
      return "Please select today & yesterday's date only.";
    }
    case "maxDate": {
      return "Future date is not allowed.";
    }
    case "disableFuture": {
      return "Future date is not allowed.";
    }
    case "invalidDate": {
      return "Your date is not valid!";
    }
    case "Please select a date": {
      return "This field is required!";
    }
    default: {
      return "";
    }
  }
};

export const revisionDateError = (error) => {
  switch (error) {
    case "minDate": {
      return "Please select date only before 7 days.";
    }
    case "maxDate": {
      return "Future date is not allowed.";
    }
    case "empty": {
      return "Please select  valid date.";
    }
    case "invalidDate": {
      return "Your date is not valid!";
    }
    default: {
      return "";
    }
  }
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "10px",
  outline: "none",
  // bgcolor: "background.paper",
  // boxShadow: 24,
  // padding: 2,
};
export const EmpCard = ({
  open,
  handleClose,
  id,
  name,
  img,
  inLoction,
  outLocation,
  OutImg,
  InImg,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            {/* <Typography gutterBottom variant="h6" component="div">
              <strong>{InImg || OutImg} Preview</strong>
            </Typography> */}
            {/* <Typography gutterBottom variant="h6" component="div">
              <strong> <LocationOnIcon /> In: </strong>

              <span style={{
                            fontSize: '13px'
                        }}>{inLoction?.address}</span>
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              <strong> <LocationOnIcon /> Out:</strong>

              <span>{outLocation?.address}</span>
            </Typography> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography gutterBottom variant="h6" component="div">
                {/* <span>{id && name && `${name}`(`${id}`)}</span> */}
                {id && name && (
                  <span>
                    {name} ({id})
                  </span>
                )}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                // sx={{
                //   position: "absolute",
                //   right: 8,
                //   top: 8,
                //   color: (theme) => theme.palette.grey[500],
                // }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <img
              src={`${baseURL}${img}`}
              alt="img"
              height={"450px"}
              width={"400px"}
            />
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export const isDateValid = (dateStr) => {
  return !isNaN(new Date(dateStr));
};
// This method is use to convert first letter of our name to capital
export let nameConverter2 = (name) => {
  let array = name
    .replace(/\s{2,}/g, " ")
    .trim()
    .split(" ");
  let charAtZeroIndex = array?.map(
    (ele) => ele?.charAt(0)?.toUpperCase() + ele?.slice(1)
  );
  return charAtZeroIndex.join(" ");
};
export const dateCompare = (resDate) => {
  const date = new Date(resDate);

  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  return y + "/" + (m <= 9 ? "0" + m : m) + "/" + (d <= 9 ? "0" + d : d);
};

export const leaveDateValidation = (error) => {
  switch (error) {
    case "minDate": {
      return "Please select date only before 7 days.";
    }
    case "maxDate": {
      return "Future date is not allowed.";
    }
    case "empty": {
      return "Please select  valid date.";
    }
    case "invalidDate": {
      return "Your date is not valid!";
    }
    default: {
      return "";
    }
  }
};

export const checkIfTodaysDateIsPreset = (dateStringToCheck) => {
  // Create a Date object for today's date
  let today = new Date();

  // Extract year, month, and day from today's date
  let yearToCheck = parseInt(dateStringToCheck.substring(0, 4));
  let monthToCheck = parseInt(dateStringToCheck.substring(5, 7)) - 1; // months are 0-indexed in JavaScript
  let dayToCheck = parseInt(dateStringToCheck.substring(8, 10));

  // Compare if year, month, and day of today's date match the date to check
  if (
    today.getFullYear() === yearToCheck &&
    today.getMonth() === monthToCheck &&
    today.getDate() === dayToCheck
  ) {
    return true;
  } else {
    return false;
  }
};
export const convertToHours = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":");
  return (
    parseInt(hours, 10) +
    parseInt(minutes, 10) / 60 +
    parseInt(seconds, 10) / 3600
  );
};

export const EmpActivityImageViewCard = ({
  open,
  handleClose,
  id,
  name,
  img,
  inLoction,
  outLocation,
  OutImg,
  InImg,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              <strong>{InImg || OutImg} Preview</strong>
            </Typography>
            {/* <Typography gutterBottom variant="h6" component="div">
              <strong> <LocationOnIcon /> In: </strong>

              <span style={{
                            fontSize: '13px'
                        }}>{inLoction?.address}</span>
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              <strong> <LocationOnIcon /> Out:</strong>

              <span>{outLocation?.address}</span>
            </Typography> */}
            <Typography gutterBottom variant="h6" component="div">
              {/* <span>{id && name && `${name}`(`${id}`)}</span> */}
              {id && name && (
                <span>
                  {name} ({id})
                </span>
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={`${baseURL}${img}`}
              alt="img"
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};
